import BaseService from "./BaseService";

export default class TaxonomiaService extends BaseService {
    getAll() {
        const request = this.requester.get(`${this.url}/api/taxonomia`, this.config).then(response => response).catch(e => {
            throw e
        }).finally(() => {
        })
        return request;
    }

    getById(param) {
        const request = this.requester.get(`${this.url}/api/taxonomia/${param}`, this.config).then(response => response).catch(e => {
            throw e
        }).finally(() => {
        })
        return request;
    }

    update(param, id) {
        const request = this.requester.post(`${this.url}/api/taxonomia/${id}`, param, this.config).then(response => response).catch(e => {
            throw e
        }).finally(() => {
        })
        return request;
    }
}
