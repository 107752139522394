<template>
    <div class="page-taxonomia-editar my-4">
        <div class="row">
            <div class="col-md-12">
                <h4> TAXONOMIA | <strong>EDITAR</strong></h4>
            </div>
        </div>
        <b-form @submit="onSubmit" @reset="onReset" v-if="show">
                <div class="row">
                    <div class="col">
                        <b-form-group id="input-group-2" label="Título principal" label-for="input-2">
                            <b-form-input
                                id="title"
                                v-model="taxonomia.title"
                                name="title"
                                required
                            ></b-form-input>
                        </b-form-group>

                        
                    </div>
                    <div class="col">
                            <b-form-group
                                id="input-group-1"
                                label="Icone"
                                label-for="input-1"
                                description="Icone só será utilizado na parte de informações."
                            >
                                <b-form-input
                                    id="icon"
                                    v-model="taxonomia.icon"
                                    name="icon"
                                ></b-form-input>
                            </b-form-group>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <b-form-group id="input-group-2" label="Descrição ou texto complementar" label-for="input-2">
                            <b-form-textarea
                                id="description"
                                v-model="taxonomia.description"
                                name="description"
                            ></b-form-textarea>
                        </b-form-group>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 d-flex">
                        <img :src="taxonomia.link" v-if="taxonomia.link" class="img-form my-4 mx-auto">
                    </div>
                    <div class="col">
                        <b-form-group id="input-group-2" label="Imagem" label-for="input-2">
                            <b-form-input
                                id="link"
                                v-model="taxonomia.link"
                                name="link"
                            ></b-form-input>
                        </b-form-group>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="mt-3">
                            <b-button type="submit" variant="primary">Salvar</b-button>
                        </div>
                    </div>
                </div>
        </b-form>
    </div>
</template>

<script>
import TaxonomiaService from "../../../services/TaxonomiaService";
import Utils from "../../../utils/utils.js";

export default {
    name: "Taxonomia",
    data() {
        return {
            taxonomia: {
                icon: '',
                title: '',
                description: null,
                link: null
            },
            show: true
        }
    },
    methods: {
        onSubmit(event) {
            event.preventDefault()
            this.taxonomiaService.update(this.taxonomia, this.$router.history.current.params.id)
            .then(() => {
                this.Utils.success('Taxonomia atualizada com sucesso!');
                this.$router.push({name:'painel.taxonomia'});
            })
        },
        onReset(event) {
            event.preventDefault()
            // Reset our form values
            this.taxonomia.icon = ''
            this.taxonomia.title = ''
            this.taxonomia.description = null
            this.taxonomia.link = []
            // Trick to reset/clear native browser form validation state
            this.show = false
            this.$nextTick(() => {
                this.show = true
            })
        }
    },
    created() {
        this.Utils = new Utils();
    },
    mounted() {
        this.taxonomiaService = new TaxonomiaService();
        this.taxonomiaService.getById(this.$router.history.current.params.id)
        .then(res => {
            this.taxonomia = res.data.data;
        })
    }
}
</script>

<style scoped>
.img-form{
    max-height: 250px;
    border-radius: 0.35rem;
    align-content: center;
    align-items: center;
    
}
</style>
